import { Component, HostListener, Injector, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TraceModule } from '@sentry/angular';
import { ActiveLogoHeader } from 'src/app/main/_layout/services/active-logo-header';
import { FooterChangeImgServiceService } from 'src/app/main/_layout/services/footer-change-img.service';
import { GiftBoxTotalService } from 'src/app/main/_layout/services/gift-box-total.service';
import { GiftBoxService } from 'src/app/main/wishlist/services/gift-box.service';
import { AppComponentBase } from 'src/app/shared/app-component-base';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends AppComponentBase implements OnInit {
  @Input() screen = 'page';
  @Input() activeLogo: any;
  currentUrl = '';
  giftToltal = 0;

  imgFooter = 'assets/images/icon/svg/footer-cross.svg';
  isShowMenuMobile = false;
  isMobileScreen = false;
  isShowFooter = true;
  currentScreen = this.screenType.cross;
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.isMobileScreen = window.innerWidth <= 575;
    this.getImageFooter();
  }

  constructor(
    injector: Injector,
    private router: Router,
    private giftBoxService: GiftBoxService,
    private giftBoxTotalService: GiftBoxTotalService,
    private footerChangeImgServiceService: FooterChangeImgServiceService,
    private activeLogoHeader: ActiveLogoHeader,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.getScreenSize();
    this.currentUrl = this.router.url.split(/[?#]/)[0];
    this.footerChangeImgServiceService.getData().subscribe(check => {
      this.currentScreen = +check;
      this.getImageFooter();
    });
    this.giftBoxTotalService.getData().subscribe(data => {
      this.giftToltal = data >= 100 ? '99+' : data;
    });
    this.activeLogoHeader.getData().subscribe(s => {
      if (s) {
        this.changeMenu('/main/wishlist/creator-view');
      }
    });
    this.activeLogoHeader.getUrlCurrent().subscribe(s => {
      if (s) {
        this.changeMenu(s);
      }
    });
  }

  isMenuItemActive(url: string) {
    if (this.currentUrl === url) {
      return true;
    }

    return false;
  }

  changeMenu(url: string) {
    this.currentUrl = url;
  }

  openGitpBox() {
    this.giftBoxService.setData(true);
    const buttonElement = document.querySelector('.bg-light');
    if (buttonElement) {
      buttonElement.classList.add('no-scroll');
    }
  }

  private getImageFooter() {
    setTimeout(() => {
      if (this.hasLoggedIn && this.isMobileScreen) {
        this.isShowMenuMobile = true;
      } else {
        this.isShowMenuMobile = false;
      }

      // Nếu là màn checkout thì ẩn footer đi ở mobile screen
      if (this.currentScreen === this.screenType.checkout) {
        if (this.isMobileScreen) {
          this.isShowFooter = false;
          return;
        } else {
          this.imgFooter = 'assets/images/icon/svg/footer-default.svg';
        }
      }
      // Màn có footer chéo ở mobile và footer thẳng ở desktop
      else if (this.currentScreen === this.screenType.mixDefaultCross) {
        if (this.isMobileScreen) {
          this.imgFooter = 'assets/images/icon/svg/footer-cross-mobile.svg';
        } else {
          this.imgFooter = 'assets/images/icon/svg/footer-default.svg';
        }
      }
      // Màn có footer thẳng ở mobile và footer chéo ở desktop
      else if (this.currentScreen === this.screenType.mixCrossDefault) {
        if (this.isMobileScreen) {
          this.imgFooter = 'assets/images/icon/svg/footer-default.svg';
        } else {
          this.imgFooter = 'assets/images/icon/svg/footer-cross.svg';
        }
      }
      // Cho các màn có footer chéo (cả mobile và desktop)
      else if (this.currentScreen === this.screenType.cross) {
        if (this.isMobileScreen) {
          this.imgFooter = 'assets/images/icon/svg/footer-cross-mobile.svg';
        } else {
          this.imgFooter = 'assets/images/icon/svg/footer-cross.svg';
        }
      }
      // Cho các màn có footer thẳng (cả mobile và desktop)
      else {
        this.imgFooter = 'assets/images/icon/svg/footer-default.svg';
      }

      this.isShowFooter = true;
      this.setBgImageFooter();
    }, 0);
  }

  private setBgImageFooter() {
    if (this.isShowFooter) {
      setTimeout(() => {
        let footerContent = document.querySelector<HTMLElement>('.footer-content');
        if (footerContent) {
          footerContent.style.backgroundImage = `url(${this.imgFooter})`;
        }
      }, 0);
    }
  }
}
