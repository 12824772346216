import { mapEnumToOptions } from '@abp/ng.core';

export enum ProductType {
  Amazone = 0,
  Rakuten = 1,
  AppleStore = 2,
  SonyStore = 3,
  EcSite = 4,
}

export const productTypeOptions = mapEnumToOptions(ProductType);
