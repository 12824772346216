import {
  AfterViewChecked,
  Component,
  ElementRef,
  HostListener,
  Injector,
  OnInit,
  Renderer2,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppComponentBase, WidthType } from 'src/app/shared/app-component-base';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { catchError, debounceTime, finalize } from 'rxjs';
import { WishListCreatorViewService } from '@proxy/wish-list/wish-list-creator-view.service';
import { Router } from '@angular/router';
import { AddWhishlistService } from '../../services/add-whishlist.service';
import { GiftStatus } from '@proxy/gifts/gift-status.enum';
import { ConfirmDeleteComponent } from '../confirm-delete/confirm-delete.component';
import { CreateWishListMannuallyDto, EcsiteInput, ProductInforWishListManuallyDto } from '@proxy/wish-list/dtos/wist-list-manually/models';
import { HttpClient } from '@angular/common/http';
import { GlobalLookupService } from '@proxy/global/global-lookup.service';
import { GiftImageDto } from '@proxy/wish-list/dtos';
import { CommonComponent } from 'src/app/shared/dft/component/common.component';
import { ValidationComponent } from 'src/app/shared/dft/component/validation-messages.component';

@Component({
  selector: 'app-item-detail-creator-manually',
  templateUrl: './item-detail-creator-manually.component.html',
  styleUrls: ['./item-detail-creator-manually.component.scss', '../../_layout.scss']
})
export class ItemDetailCreatorManuallyComponent extends AppComponentBase
  implements OnInit, AfterViewChecked {
  ref: DynamicDialogRef | undefined;
  formData: FormGroup;
  itemDetail: any;
  images = [];
  loading = false;
  itemListOption: FormArray;
  isView = false;
  isEdit = false;
  isShow = false;
  asin = '';
  link = '';
  loadingData = false;
  dataRespneError = false;
  tabIndex = 0;
  edit = false;
  isMobileScreen = false;
  giftStatus = GiftStatus;
  giftStatusDetail: any;
  URLUploadCover = this.serverUrl + `/api/Upload/UploadGiftImage`;
  imageCheck = ['jpg', 'png', 'jpeg'];
  imageAccepts: string = 'image/jpeg, image/png, image/jpg';
  base64: any;
  base64Main: any;
  loadingDataImgMain = false;
  loadingDataImgSmall = false;
  widthType = WidthType;

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.isMobileScreen = window.innerWidth <= 767;
  }
  arrSmallImagesUrl = ['assets/images/icon/svg/+.svg'
  ]
  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    public dialog: DialogService,
    public wishListCreatorViewService: WishListCreatorViewService,
    public globalLookupAppService: GlobalLookupService,
    private dialogService: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private addWhishlistService: AddWhishlistService,
    injector: Injector,
    private fb: FormBuilder,
    private http: HttpClient,
  ) {
    super(injector);
  }
  ngAfterViewChecked(): void {
    this.replaceImg('assets/images/icon/svg/dropdown.svg', '.custom-dropdown .p-icon-wrapper');

  }
  replaceImg(src: string, className: string) {
    const buttonElement = document.querySelectorAll(className);
    if (buttonElement) {
      buttonElement.forEach(element => {
        const newImg = document.createElement('img');
        newImg.src = src;
        const svgElement = element.querySelector('svg');
        if (svgElement && newImg) {
          svgElement.replaceWith(newImg);
        }
      });
    }
  }
  disableFormArray(formControl: FormControl) {
    formControl.disable();
  }
  ngOnInit(): void {
    this.getScreenSize();
    setTimeout(() => {
      this.isShow = true;
      let el = document.querySelector<HTMLElement>('.p-dialog');
      el.style.boxShadow = 'unset';
    }, 1);
    this.initForm();
    this.isEdit = this.dialogService.data.isEdit;
    if (!this.isEdit) {
      this.formData.disable();
    } else {
      this.formData.enable();
      this.formData.controls.TotalProductAmount.disable();
    }
    this.isView = this.dialogService.data.isView;
    if (this.isView) {
      this.getDataEdit(this.dialogService.data.id);
    } else {
      this.getData(this.dialogService.data.item);
    }
  }
  getDataEdit(id: any) {
    this.loadingData = true;
    this.wishListCreatorViewService
      .getWishListEcsiteForEdit(id)
      .pipe(
        catchError(err => {
          this.handlerError(err);
          this.dataRespneError = true;
          return err;
        }),
        finalize(() => {
          this.giftStatusDetail = this.itemDetail.status;
          if (this.itemDetail.status === GiftStatus.Queued) {
            this.formData.disable();
          } else if (this.itemDetail.status === GiftStatus.Created) {
            this.formData.enable();
            this.formData.controls.TotalProductAmount.disable();
          }
          this.setValue();
          this.loadingData = false;
          this.setTotalProductAmount();
        })
      )
      .subscribe((rs: ProductInforWishListManuallyDto) => {
        this.itemDetail = rs;
        let image: GiftImageDto = {
          itemSrc: rs.mainImageUrl,
          thumbnailSrc: rs.mainImageUrl,
          isMain: true,
          order: 0,
        }
        this.images.push(image);
        rs.smallImagesUrl.forEach(element => {
          let i = 1;
          const image: GiftImageDto = {
            itemSrc: element,
            thumbnailSrc: element,
            isMain: false,
            order: i,
          }
          i++;
          this.images.push(image);
          this.arrSmallImagesUrl.push(element)
        });
      });
  }
  getData(id: any) {
    this.formData.disable();
    this.images = [];
    this.dataRespneError = false;
    this.loadingData = true;
    let input: EcsiteInput = {
      url: id,
      variations: null,
    }
    this.globalLookupAppService
      .getProductDetailFromEcsiteByInput(input)
      .pipe(
        catchError(err => {
          this.handlerError(err);
          this.dataRespneError = true;
          return err;
        }),
        finalize(() => {
          this.setValue();
          this.loadingData = false;
          this.setTotalProductAmount();
          this.formData.enable();
          this.formData.controls.TotalProductAmount.disable();
        })
      )
      .subscribe((rs: ProductInforWishListManuallyDto) => {
        this.itemDetail = rs;
        this.itemDetail = rs;
        this.itemDetail.quantity = 1;
        let image: GiftImageDto = {
          itemSrc: rs.mainImageUrl,
          thumbnailSrc: rs.mainImageUrl,
          isMain: true,
          order: 0,
        }
        this.images.push(image);
        rs.smallImagesUrl.forEach(element => {
          let i = 1;
          const image: GiftImageDto = {
            itemSrc: element,
            thumbnailSrc: element,
            isMain: false,
            order: i,
          }
          i++;
          this.images.push(image);
          this.arrSmallImagesUrl.push(element);
        });
        if (rs.url == null) {
          this.formData.enable();
          this.formData.controls.TotalProductAmount.disable();
          this.loadingData = false;
          this.itemDetail.url = id;
          this.showErrorMessage(this.L('::CreatorDonate:Profile:AddWishListMannually:GetInformationErorr'));
        }
      });
  }
  initForm() {
    this.formData = this.fb.group({
      Title: [undefined, Validators.required],
      ProductUnitPrice: [undefined, Validators.compose([Validators.required, ValidationComponent.ValidateNumberMin1])],
      Quantity: [undefined, Validators.required],
      Postage: [undefined],
      TotalProductAmount: [undefined],
      Remarks: [undefined],
      message: [undefined],
    });
    this.formData.controls.Quantity.valueChanges.pipe(debounceTime(350)).subscribe(valueChange => {
      if (valueChange) {
        if (valueChange > 10) {
          this.formData.get('Quantity')?.setValue(10);
          return;
        }
        this.setTotalProductAmount();
      }
    });
    this.formData.controls.Postage.valueChanges.pipe(debounceTime(350)).subscribe(s => {
      this.setTotalProductAmount();
    });
    this.formData.controls.ProductUnitPrice.valueChanges.pipe(debounceTime(350)).subscribe(s => {
      this.setTotalProductAmount();
    });
  }

  // initRxjs() {
  //   this.quantitySub$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(valueChange => {
  //     this.formData.get('Quantity')?.setValue(valueChange.value);
  //     this.checkShipping();
  //   });
  //   this.productUnitPriceSub$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(valueChange => {
  //     this.formData.get('ProductUnitPrice')?.setValue(valueChange);
  //     this.checkShipping();
  //   });
  //   this.postageSub$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(valueChange => {
  //     this.formData.get('Postage')?.setValue(valueChange);
  //   });
  // }

  onInputPostage(event: any) {
    const numericValue = this.convertPriceStringToNum(event.target.value);
    this.formData.get('Postage')?.setValue(numericValue);
  }

  onInputProductUnitPrice(event: any) {
    const numericValue = this.convertPriceStringToNum(event.target.value);
    this.formData.get('ProductUnitPrice')?.setValue(numericValue);
  }

  onInputQuantity(event: any) {
    let numericValue = this.convertPriceStringToNum(event.target.value);
    if (numericValue) {
      if (numericValue && numericValue > 10) numericValue = 10;
      setTimeout(() => this.formData.get('Quantity')?.setValue(numericValue));
    }
  }

  // checkShipping() {
  // const quantity = this.formData.controls.Quantity.value;
  // const productUnitPrice = this.formData.controls.ProductUnitPrice.value;
  // if (quantity !== null && quantity !== undefined && quantity !== '' && productUnitPrice !== null && productUnitPrice !== undefined && productUnitPrice !== '') {
  //   this.wishListCreatorViewService.checkUserInHonshuOrShikoku().subscribe(rs => {
  //     const total = quantity * productUnitPrice;
  //     if (total > 2000) {
  //       this.formData.controls.Postage.setValue(0)
  //     }
  //     else {
  //       const shipFee = rs ? 410 : 450;
  //       this.formData.controls.Postage.setValue(shipFee);
  //     }
  //   });
  // }
  // else {
  //   this.formData.controls.Postage.setValue(0);
  //   this.formData.controls.TotalProductAmount.setValue(0);
  // }
  // }

  setTotalProductAmount() {
    const total = Math.round(
      this.formData.controls.ProductUnitPrice.value * this.formData.controls.Quantity.value +
      this.formData.controls.Postage.value +
      this.formData.controls.ProductUnitPrice.value * this.formData.controls.Quantity.value * 0.07);
    this.formData.controls.TotalProductAmount.setValue(total);
  }
  get itemOption() {
    return this.formData.get('opTions') as FormArray;
  }
  onFileSmallImagesUrl(event) {
    if (event.target.files.length > 0) {
      this.loadingDataImgSmall = true;
      const file = event.target.files[0];
      const arr = file.name.split('.');
      const fileType = arr[arr.length - 1].toString().toLowerCase();
      if (file.size > 5242880 || !this.imageCheck.includes(fileType)) {
        this.showErrorMessage(this.L('::Wishlist:Creator:UpdateImgErrorSize'));
        this.loadingDataImgSmall = false;
        event.target.value = ''; // reset value when file upload error
        return;
      } else {
        const reader = new FileReader();
        reader.addEventListener(
          'load',
          () => {
            // convert image file to base64 string
            let formData = new FormData();
            formData.append('anhDaiDien', file);

            this.http
              .post(this.URLUploadCover, formData, { responseType: 'text' })
              .pipe(
                catchError(err => {
                  this.handlerErrorUploadfile(JSON.parse(err.error));
                  this.loadingDataImgSmall = false;
                  return err;
                }),
                finalize(() => {
                  this.loadingDataImgSmall = false;
                })
              )
              .subscribe((result: string) => {
                this.showSuccessMessage(this.L('::Wishlist:Creator:UpdateImgCoverProfile'));
                this.arrSmallImagesUrl.splice(1, 0, result);
              });
          }
        );
        reader.readAsDataURL(event.target.files[0]);
      }
    }
  }
  removeSmallImagesUrl(index: any) {
    this.arrSmallImagesUrl.splice(index, 1);
  }
  onFileMainImageUrl(event) {
    if (event.target.files.length > 0) {
      this.loadingData = true;
      const file = event.target.files[0];
      const arr = file.name.split('.');
      const fileType = arr[arr.length - 1].toString().toLowerCase();
      if (file.size > 5242880 || !this.imageCheck.includes(fileType)) {
        this.showErrorMessage(this.L('::Wishlist:Creator:UpdateImgErrorSize'));
        this.loadingData = false;
        event.target.value = ''; // reset value when file upload error
        return;
      } else {
        const reader = new FileReader();
        reader.addEventListener(
          'load',
          () => {
            // convert image file to base64 string
            let formData = new FormData();
            formData.append('anhMainUrl', file);
            this.http
              .post(this.URLUploadCover, formData, { responseType: 'text' })
              .pipe(
                catchError(err => {
                  this.handlerErrorUploadfile(JSON.parse(err.error));
                  this.loadingData = false;
                  return err;
                }),
                finalize(() => {
                  this.loadingData = false;
                })
              )
              .subscribe((result: string) => {
                this.showSuccessMessage(this.L('::Wishlist:Creator:UpdateImgCoverProfile'));
                this.itemDetail.mainImageUrl = result;
              });
          }
        );
        reader.readAsDataURL(event.target.files[0]);
      }
    }
  }
  getcontrols(form: FormGroup) {
    const controlKeys = Object.keys(form.controls);
    return controlKeys[0] || null;
  }
  setValue() {
    const url = new URL(this.itemDetail.url);
    const domain = url.hostname;
    this.link = domain;
    this.formData.controls.Title.setValue(this.itemDetail.name);
    this.formData.controls.Remarks.setValue(this.itemDetail.description);
    if (this.itemDetail.quantity !== null) {
      this.formData.controls.Quantity.setValue(this.itemDetail.quantity);

    }
    if (this.itemDetail.shipFee !== null) {
      this.formData.controls.Postage.setValue(this.itemDetail.shipFee);
    } else {
      this.formData.controls.Postage.setValue(null);
    }
    this.formData.controls.message.setValue(this.itemDetail.message);
    this.formData.controls.ProductUnitPrice.setValue(this.itemDetail.price);
    this.formData.controls.TotalProductAmount.disable();
  }

  closeDialog(): void {
    // Truyền dữ liệu khi đóng dialog
    this.dialogRef.close(false);
  }
  saveData(): void {
    if (CommonComponent.getControlErr(this.formData) === '') {
      this.loading = true;
      setTimeout(() => {
        const arrSmallImgsave = this.arrSmallImagesUrl.filter(rs => rs !== 'assets/images/icon/svg/+.svg');
        if (!this.isView) {
          const input: CreateWishListMannuallyDto = {
            url: this.itemDetail.url,
            name: this.formData.controls.Title.value.trim(),
            quantity: this.formData.controls.Quantity.value,
            shipFee: this.formData.controls.Postage.value,
            smallImagesUrl: arrSmallImgsave,
            description: this.formData.controls.Remarks.value,
            mainImageUrl: this.itemDetail.mainImageUrl,
            price: this.formData.controls.ProductUnitPrice.value,
            category: '',
            message: this.formData.controls.message.value,
          };
          this.wishListCreatorViewService
            .addWishListEcsite(input)
            .pipe(
              catchError(err => {
                this.handlerError(err);
                return err;
              }),
              finalize(() => {
                this.loading = false;
              })
            )
            .subscribe(res => {
              this.dialogRef.close(true);
              this.addWhishlistService.setData(true);
              this.showSuccessMessage(this.L('::Wishlist:AddWishList:SuccessfullyAdded'));
            });
        } else {
          const input: CreateWishListMannuallyDto = {
            url: this.itemDetail.url,
            name: this.formData.controls.Title.value.trim(),
            quantity: this.formData.controls.Quantity.value,
            shipFee: this.formData.controls.Postage.value,
            smallImagesUrl: arrSmallImgsave,
            description: this.formData.controls.Remarks.value,
            mainImageUrl: this.itemDetail.mainImageUrl,
            price: this.formData.controls.ProductUnitPrice.value,
            category: '',
            message: this.formData.controls.message.value,
          }
          this.wishListCreatorViewService
            .updateWishListEcsite(this.dialogService.data.id, input)
            .pipe(
              catchError(err => {
                this.handlerError(err);
                return err;
              }),
              finalize(() => {
                this.loading = false;
              })
            )
            .subscribe(res => {
              this.dialogRef.close(true);
              this.showSuccessMessage(this.L('::Wishlist:UpdateWishList:SuccessfullyUpdated'));
            });
        }
      }, 500);
    } else {
      this.showErrorMessage(this.L('::CreatorDonate:Profile:SomeItemsHaveNotBeenSet'));
    }
  }

  delete() {
    let contentStyle = {
      overflow: 'auto',
      padding: '60px 80px',
      borderRadius: '20px',
    };

    if (this.isMobileScreen) {
      contentStyle = {
        overflow: 'auto',
        padding: '24px',
        borderRadius: '8px',
      };
    }

    this.ref = this.dialog.open(ConfirmDeleteComponent, {
      width: '526px',
      contentStyle: contentStyle,
      baseZIndex: 10000,
      maximizable: false,
      showHeader: false,
      dismissableMask: false, // click ra ngoài để tắt dialog
    });
    this.ref.onClose.subscribe(dataReturn => {
      if (dataReturn.data) {
        this.wishListCreatorViewService
          .deleteWishList(this.dialogService.data.item.id)
          .pipe(
            catchError(err => {
              this.handlerError(err);
              return err;
            }),
            finalize(() => { })
          )
          .subscribe(res => {
            this.dialogRef.close(true);
            this.showSuccessMessage(this.L('::Wishlist:UpdateWishList:SuccessfullyDeleted'));
          });
      }
    });
  }
  getTitle(text) {
    switch (text.toLowerCase()) {
      case 'variation':
        return this.L('::Wishlist:Creator:Detail:Variation');
      case 'color':
        return this.L('::Wishlist:Creator:Detail:Color');
      default:
        return text;
    }
  }
  activeIndexChange(className: string) {
    setTimeout(() => {
      const classActive = '.' + className + ' .p-galleria-thumbnail-items';
      const element = this.elementRef.nativeElement.querySelector(classActive);
      if (element) {
        this.renderer.setStyle(element, 'transform', 'translate3d(0%, 0px, 0px)'); // Thay đổi giá trị transform tùy ý
      }
    }, 1);
  }
  checkEditImg() {
    if ((!this.loadingData && this.giftStatusDetail == this.giftStatus.Created) || (!this.loadingData && this.giftStatusDetail == null)) {
      return true;
    }
    else {
      return false;
    }
  }
  checkAddImg() {
    if (((!this.loadingData && this.giftStatusDetail == this.giftStatus.Created) || (!this.loadingData && this.giftStatusDetail == null)) && this.arrSmallImagesUrl.length < 21) {
      return true;
    }
    else {
      return false;
    }

  }
  checkViewImg() {
    if ((!this.loadingData && this.giftStatusDetail !== this.giftStatus.Created && this.giftStatusDetail != null)) {
      return true;
    }
    else {
      return false;
    }
  }
  checkLoadingViewImg() {
    if ((this.loadingData && this.giftStatusDetail !== this.giftStatus.Created && this.giftStatusDetail != null)) {
      return true;
    }
    else {
      return false;
    }
  }
}

