import type { ErrorResultDto } from './dtos/models';
import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { ListWishlistResultsDto } from '../wish-list/dtos/models';
import type { EcsiteInput, ProductInforWishListManuallyDto } from '../wish-list/dtos/wist-list-manually/models';

@Injectable({
  providedIn: 'root',
})
export class GlobalLookupService {
  apiName = 'Default';
  

  checkShipFee = (userId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>({
      method: 'POST',
      url: `/api/app/global-lookup/check-ship-fee/${userId}`,
    },
    { apiName: this.apiName,...config });
  

  getErrorMessage = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, ErrorResultDto>({
      method: 'GET',
      url: '/api/app/global-lookup/error-message',
    },
    { apiName: this.apiName,...config });
  

  getGiftsLinkFromEcsiteByWishlistUrl = (wishlistUrl: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ListWishlistResultsDto>({
      method: 'GET',
      url: '/api/app/global-lookup/gifts-link-from-ecsite',
      params: { wishlistUrl },
    },
    { apiName: this.apiName,...config });
  

  getProductDetailFromEcsiteAnonymousByInput = (input: EcsiteInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ProductInforWishListManuallyDto>({
      method: 'GET',
      url: '/api/app/global-lookup/product-detail-from-ecsite-anonymous',
      params: { url: input.url, variations: input.variations },
    },
    { apiName: this.apiName,...config });
  

  getProductDetailFromEcsiteByInput = (input: EcsiteInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ProductInforWishListManuallyDto>({
      method: 'GET',
      url: '/api/app/global-lookup/product-detail-from-ecsite',
      params: { url: input.url, variations: input.variations },
    },
    { apiName: this.apiName,...config });
  

  getUsersIsDeletedByUserIds = (userIds: string[], config?: Partial<Rest.Config>) =>
    this.restService.request<any, string[]>({
      method: 'GET',
      url: '/api/app/global-lookup/users-is-deleted',
      params: { userIds },
    },
    { apiName: this.apiName,...config });
  

  haveEnoughPaymentInfoAndAddress = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>({
      method: 'POST',
      url: '/api/app/global-lookup/have-enough-payment-info-and-address',
    },
    { apiName: this.apiName,...config });
  

  userIsDeletedByUserId = (userId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>({
      method: 'POST',
      url: `/api/app/global-lookup/user-is-deleted/${userId}`,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
